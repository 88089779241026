import React from 'react';
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse';
import Body2 from './Body2'

import { textSpace, licensesPrice } from '../components/styles/ProductCloud.styles'

const App = ( ) => (
  <Collapse className='border-line'>
    <Panel header='Entenda os tipos de licenças.'>
      <Body2
        color='black'
        variant='mediumEmphasis'
        mt={[3]}
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
        Licença Desktop/Server: Permite jobs de backup a nível arquivos, imagens e estado do sistema operacional. É compatível com sistemas operacionais Windows Desktop/Server, Linux e MacOS (apenas para o job de backup de arquivos). <span css={licensesPrice}>R$ 45/mês.</span>
        <div css={textSpace} />
        Licença MSSQL Server: Utilizada para fins de backup do serviço de banco de dados MSSQL, também com a opção de realizar os jobs de backup da licença Desktop Server. Licença disponível apenas em ambientes Windows.<br /> <span css={licensesPrice}>R$ 60/mês.</span>
        <div css={textSpace} />
        Licença Exchange Server: Utilizada para fins de backup do serviço de e-mails exchange, também com a opção de realizar os jobs de backup da licença Desktop Server. <span css={licensesPrice}>R$ 60/mês.</span>
        <div css={textSpace} />
        Licença Ultimate: Esta é a licença completa da solução de Cloud Backup, onde todas as funcionalidades citadas acima estão incluídas. <span css={licensesPrice}>R$ 115/mês.</span> 
      </Body2>
    </Panel>
  </Collapse>
);

export default App