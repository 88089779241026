import React from 'react';
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse';
import Body2 from './Body2'

const App = ({
  content,
  title
}) => (
  <Collapse>
    <Panel header={title}>
      <Body2
        color='black'
        variant='mediumEmphasis'
        mt={[3]}
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {content}
      </Body2>
    </Panel>
  </Collapse>
);

export default App