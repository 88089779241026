import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import { graphql, Link }  from 'gatsby'
import compose from 'lodash/fp/flowRight'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import ContainedButton from '../components/ContainedButton'
import OutlinedButton from '../components/OutlinedButton'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Body2 from './../components/Body2'
import PricePerFeature from '../components/PricePerFeature'
import GarantaSuaNuvem from '../images/cloud-backup/nuvem.png'
import EleveSeuPotencial from '../images/cloud-backup/eleve_seu_potencial.png'
import ArrowR from '@material-ui/icons/ArrowForward'
import FolderShared from '@material-ui/icons/FolderShared'
import PersonPin from '@material-ui/icons/PersonPinRounded'
import Comment from '@material-ui/icons/Comment'
import UserVerified from '@material-ui/icons/VerifiedUser'
import Search from '@material-ui/icons/Pageview'
import NewFolder from '@material-ui/icons/CreateNewFolder'
import Build from '@material-ui/icons/Build'
import Accordion from '../components/Accordion'
import AccordionPrice from '../components/AccordionLine'
import TypeLicenses from '../components/TypeLicenses'

import { Container } from '../components/styles/Layout.styles'
import { withSolutions } from '../components/SolutionProvider'

import { 
  faqCloudServer, 
  imgPotencial, 
  listCloudItem, 
  highlightBox, 
  IdProtectList, 
  precosIcons, 
  imgVemPraNuvem } from '../components/styles/ProductCloud.styles'
import { 
  termosBox 
} from '../components/styles/Contact.styles'
import { titleStyle, titleForm } from '../components/styles/InternalPage.styles'
import GarantaSuaNuvemForm from '../components/VouPraNuvemForm'
import { validateRequiredEmail } from '../utils/validations'
const nuvemFields = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const initialModals = {
  support: false,
  moreInfo: false
}

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[50]}
              mb={[50]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 500]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H4
                    style={{
                      fontSize: 11.8,
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      marginBottom: 8
                    }}
                  >
                    CLOUD BACKUP HOSTDIME
                  </H4>
                  <H3
                    color='black'
                    variant='highEmphasis'
                    mb={[5, 5, 3]}
                    css={titleStyle}
                  >
                    Sua solução de backup 100% gerenciada
                  </H3>
                  <Div
                    display={['block', 'block', 'none']}
                    mb={[5]}
                    maxWidth={[310, 310, 327, 500]}
                  >
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[0, 0, 3]}
                    maxWidth={['90%', 422, 416, 500]}
                  >
                    Com o Cloud Backup da HostDime, você reduz os custos de manter um servidor local e protege a sua empresa dos riscos de perder todos os dados por problemas técnicos ou ataques virtuais.
                    {false && <Div
                      mt={[5]}
                      alignItems={['center', 'center', 'start', 'start']}
                    >
                      <ContainedButton
                        type='btnContainedLarge'
                        Link='/precos-cloud-server'
                      >
                        VER PREÇOS E PLANOS
                      </ContainedButton>
                    </Div>}
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '100%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[30, 50]}
            justifyContent={['left', 'left', 'center']}
          >
            <H4
              color='black'
              variant='highEmphasis'
              css={titleForm}
              maxWidth={['90%', 422, 400, 400]}
              style={{
                textAlign: 'center'
              }}
            >
              Vantagens
            </H4>
          </Row>
          <Row
            mt={30}
            mb={46}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', '100%', 855, 856]}
              justifyContent={['center']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div
                  marginBottom={[0, 30]}
                  css={IdProtectList}
                >
                  <FolderShared 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Facilidade da gestão de seus dados</span>
                    <Body2
                      selected
                      on='onSurface'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Tenha um portal com acesso web com informações completas e visão centralizada de todos os seus backups em todos os dispositivos cadastrados para utilizar o serviço.
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <PersonPin 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Mais mobilidade para a sua empresa</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Acesse seus arquivos de qualquer lugar, utilizando tablet, smart ou computador.
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <Comment 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Suporte especializado 24h</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Com um suporte especializado e sempre disponível, sua equipe de TI terá mais segurança nas soluções básicas e terá mais tempo para criar e otimizar processos de inovação.
                    </Body2>
                  </Div>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <UserVerified 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Segurança e disponibilidade</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Além das conexões seguras, temos uma equipe de analistas de segurança e analistas técnicos disponível 24h para acompanhar a integridade dos serviços da HostDime.
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <Search 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Agilidade na recuperação de arquivos</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Com apenas o nome do arquivo e alguns cliques, você acessa seus dados de backup.
                    </Body2>
                  </Div>
                </div>
              </Column>
            </Div>
          </Row>
        </Container>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '100%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[30, 30, 50]}
            justifyContent={['left', 'center']}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <H4
              color='black'
              variant='highEmphasis'
              css={titleForm}
              maxWidth={['90%', 422, 400, 400]}
              style={{
                textAlign: 'center',
                marginBottom: 16
              }}
            >
              Preços
            </H4>
            <Div
              maxWidth={['90%', 400]}
              style={{
                textAlign: 'center'
              }}
            >
              <Body2
                selected
                on='onSurface'
                variant='mediumEmphasis'
                order={[2, 2, 1]}
              >
                Escolha a quantidade de espaço necessário e a quantidade de dispositivos a serem licenciados. 
              </Body2>
            </Div>
          </Row>
          <Row
            mt={30}
            mb={70}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              justifyContent={['center']}
              alignItems={['center', 'center', 'flex-start', 'flex-start']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                mb={[8, 8,  8,  0]}
                mr={[0 , 0, 144]}
              >
                <Div
                  maxWidth={['100%', 400, 328]}
                  alignItems={['center']}
                >
                  <div css={precosIcons}>
                    <NewFolder 
                      style={{
                        color: '#fff'
                      }}
                    />
                  </div>
                  <H5
                    color='black'
                    variant='highEmphasis'
                    mb={[2]}
                    maxWidth={['90%', 400, 328, 328]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Quantidade de espaço e em qual data center será armazenado:
                  </H5>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    maxWidth={['90%', 400, 328, 280]}
                    style={{
                      textAlign: 'center',
                      marginBottom: 30
                    }}
                  >
                    Flexibilidade para escolher em qual data center vai armazenar seus dados: Orlando ou João Pessoa.
                  </Body2>
                </Div>
                <Div
                  maxWidth={['100%', 328]}
                  style={{
                    margin: '0 auto',
                    paddingBottom: 16
                  }}
                >
                  <PricePerFeature
                    productName='Zonas'
                    unity=''
                    unityPrice='por TB/Mês'
                    hour='USA'
                    priceHour='R$ 153,60'
                    month='Brasil JP'
                    priceMonth='R$ 256,00'
                  />
                </Div>
                <Div
                  maxWidth={['100%', 328]}
                  style={{
                    margin: '0 auto',
                  }}
                >
                  <AccordionPrice 
                    title='Como escolher a melhor Zona para o meu negócio?'
                    content='Para quem precisa que todos os dados da sua empresa estejam aqui no país, a Zona no Brasil é a ideal e atende aquelas que precisam de Compliance no âmbito nacional. Já a Zona USA tem um melhor custo-benefício por causa do tráfego mensal e, consequentemente, do espaço em discos.'
                  />
                </Div>
              </Column>
              <Column
                mt={[50, 50, 0]}
                mb={[8, 8,  8,  0]}
              >
                <Div
                  maxWidth={['100%', 400, 328]}
                  alignItems={['center']}
                >
                  <div css={precosIcons}>
                    <Build 
                      style={{
                        color: '#fff'
                      }}
                    />
                  </div>
                  <H5
                    color='black'
                    variant='highEmphasis'
                    mb={[2]}
                    maxWidth={['90%', 400, 200, 200]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Licenças para cada dispositivo
                  </H5>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    maxWidth={['90%', 400, 328, 328]}
                    style={{
                      textAlign: 'center',
                      marginBottom: 30
                    }}
                  >
                    Pague apenas pelo dispositivo assegurado.
                  </Body2>
                </Div>
                <Div
                  maxWidth={['100%', 328]}
                  style={{
                    marginBottom: 16,
                    margin: '0 auto'
                  }}
                >
                  <PricePerFeature
                    productName='Licenças Cloud Backup'
                    unity=''
                    unityPrice='A partir de'
                    hour='Mensal'
                    priceHour='R$ 45,00'
                    month='Anual'
                    priceMonth='R$ 450,00'
                  />
                </Div>
                <Div
                  maxWidth={['100%', 328]}
                  style={{
                    margin: '0 auto',
                    paddingTop: 16,
                    paddingBottom: 16
                  }}
                >
                  <TypeLicenses />
                </Div>
                <Div
                  maxWidth={['100%', 328]}
                  style={{
                    margin: '0 auto'
                  }}
                >
                  <AccordionPrice
                    title='Por que preciso da licença Cloud Backup?'
                    content='Para que o backup seja realizado, é necessário que os ativos estejam licenciados na plataforma Cloud Backup, de acordo com o ambiente que será copiado.'
                  />
                </Div>
              </Column>
            </Div>
          </Row>
        </Container>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '50%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'column', 'row']}
              mt={[34.5, 35, 70, 70]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                  alignItems={['center']}
                >
                  <img 
                    src={GarantaSuaNuvem} 
                    style={{
                      width: 100
                    }}
                  />
                  <H4
                    color='black'
                    variant='highEmphasis'
                    mb={[3]}
                    css={titleForm}
                    maxWidth={['90%', 422, 400, 400]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Garanta sua Nuvem
                  </H4>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[4, 4, 0, 0]}
                    maxWidth={['90%', 422, 400, 400]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Entre em contato com nossos consultores e conheça as vantagens do serviço para as suas necessidades. Mais flexibilidade, otimização de custos e uma atenção dedicada para a segurança de sua empresa.
                  </Body2>
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                  maxWidth={['100%', 422, 400, 400]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[0, 0, 3]}
                    maxWidth={['100%', 422, 416, 416]}
                    alignItems={['center', 'center', 'normal', 'normal']}
                  >
                    <Div
                      style={{
                        marginBottom: 54,
                        textAlign: 'center'
                      }}
                    >
                      <GarantaSuaNuvemForm
                        fields={nuvemFields}
                        formId={4358}
                        submitText='Enviar'
                      />
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <section css={faqCloudServer}>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[50]}
            justifyContent='center'
          >
            <H4>Tire suas Dúvidas</H4>
          </Row>
          <Row
            mb={[40, 70]}
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422]}
              >
                <Accordion 
                  title='O que é Cloud Backup?'
                  content='O Cloud Backup é uma forma de backup que armazena cópias das suas informações (de bancos de dados, dados da rede, pessoais, VMs e outras) em um servidor na nuvem, usando um link de internet.'
                />
                <Accordion 
                  title='Como posso confiar que meus dados estarão seguros na nuvem?'
                  content='Além da escolha de um Data Center confiável, todo o processo de criação de cópia do backup é garantia de segurança: utiliza-se um aplicativo que permite uma customização de horários e uso de link de internet. No horário pré-determinado, o aplicativo coleta, comprime, criptografa e transfere os dados para a nuvem. A escolha do tráfego criptografado assegura toda a confiabilidade que os dados da sua empresa precisam.'
                />      
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <section style={{ backgroundColor: '#9013fe' }}>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[40, 70]}
              mb={[30, 30, 8]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'center', 'flex-start', 'flex-start']}
              justifyContent={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                maxWidth={['100%', 390, 326, 326]}
                display={['none', 'none', 'flex', 'flex']}
                style={{
                  justifyContent: 'flex-end',
                  marginRight: 26
                }}
              >
                <img css={imgPotencial} src={EleveSeuPotencial} alt='Cloud Server HostDime' />
              </Column>
              <Column
                width={[1, 1, 1, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H4
                    style={{
                      fontSize: 11.8,
                      color: 'rgba(255, 255, 255, 0.6)',
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      marginBottom: 8
                    }}
                  >
                    CLOUD SERVER HOSTDIME
                  </H4>
                  <H4
                    color='white'
                    variant='highEmphasis'
                    mb={[3]}
                    maxWidth={[328, 400]}
                  >
                    Eleve seu potencial
                  </H4>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    maxWidth={['90%', 400]}
                    mb={[5, 5]}
                    style={{
                      color: '#fff'
                    }}
                  >
                    Conheça também o Cloud Server HostDime e amplie todas as possibilidades do seu negócio através de uma plataforma moderna e intuitiva.
                    <Div
                      mt={[5]}
                      alignItems={['center', 'center', 'start', 'start']}
                      display={['none', 'none', 'flex']}
                    >
                      <OutlinedButton
                        type='btnOutlinedMediumV'
                        Link='/cloud-server'
                        style={{
                          color: 'red'
                        }}
                      >
                        VEM PRA NUVEM
                      </OutlinedButton>
                    </Div>
                  </Body2>
                </Div>
                <Div
                  css={imgVemPraNuvem}
                >
                  <img css={imgPotencial} src={EleveSeuPotencial} alt='Eleve seu potencial' />
                </Div>
              </Column>
            </Row>
            <Row
              mb={[50, 54]}
              flexDirection={['column', 'column', 'unset', 'unset']}
            >
              <Div 
                flexDirection={['column', 'column', 'unset', 'unset']}
                css={highlightBox}>
                <Column
                  width={[1, 1, 1/2, 1/2]}
                >
                  <div css={listCloudItem}>
                    <ArrowR 
                      style={{
                        color: 'rgba(255, 255, 255, 0.54)',
                        marginRight: 16
                      }}
                    />
                    <span>Otimização de Custos</span>
                  </div>
                  <div css={listCloudItem}>
                    <ArrowR 
                      style={{
                        color: 'rgba(255, 255, 255, 0.54)',
                        marginRight: 16
                      }}
                    />
                    <span>Flexibilidade de Recursos</span>
                  </div>
                  <div css={listCloudItem}>
                    <ArrowR 
                      style={{
                        color: 'rgba(255, 255, 255, 0.54)',
                        marginRight: 16
                      }}
                    />
                    <span>Segurança da Informação</span>
                  </div>
                </Column>
                <Column
                  width={[1, 1, 1/2, 1/2]}
                >
                  <div css={listCloudItem}>
                    <ArrowR 
                      style={{
                        color: 'rgba(255, 255, 255, 0.54)',
                        marginRight: 16
                      }}
                    />
                    <span>Autonomia de Gerenciamento</span>
                  </div>
                  <div css={listCloudItem}>
                    <ArrowR 
                      style={{
                        color: 'rgba(255, 255, 255, 0.54)',
                        marginRight: 16
                      }}
                    />
                    <span>Confiabilidade & Alta<br /> Disponibilidade</span>
                  </div>
                </Column>
              </Div>
              <Div
                display={['flex', 'flex', 'none']}
                alignItems={['center']}
                style={{
                  marginTop: 34
                }}
              >
                <OutlinedButton
                  type='btnOutlinedMediumV'
                  Link='/precos-cloud-server'
                  style={{
                    color: 'red'
                  }}
                >
                  VEM PRA NUVEM
                </OutlinedButton>
              </Div>
            </Row>
          </Div>
        </Div>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='Cloud Backup HostDime'
        description='Proteja seus dados de problemas técnicos e ataques virtuais com o Cloud Backup da HostDime. O melhor custo-benefício e a maior segurança.'
      />
      <PageTemplate
        title='Cloud Backup HostDime'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-backup/cloud_backup_hostdime@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
