import { css, keyframes } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledNamePageBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledNamePage = css`
  ${styledNamePageBps};
`

export const cookieMarginBps = css(
  mq({
    marginLeft: [16, 16, 130, 100],
    marginRight: [16, 16, 130, 130]
  })
)

export const h4Style = css(
  mq({
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [500, 500, 500, 'normal']
  })
)

export const IsoTitle = css(
  mq({
    fontSize: [44, 44, 44, 59.9],
    textAlign: ['center', 'center', 'center', 'left'],
    marginTop: [50, 50, 50, 0],
    marginBottom: [16]
  })
)

export const IsoSubtitle = css(
  mq({
    fontSize: [18],
    textAlign: ['center', 'center', 'center', 'left'],
    marginBottom: [50, 30],
    color: ['#fff'],
    opacity: [0.8],
    fontWeight: [500],
    lineHeight: [1.33]
  })
)

export const titleStyle = css(
  mq({
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 'normal']
  })
)

export const titleForm = css(
  mq({
    fontSize: [33, 33.6],
    fontWeight: [600]
  })
)


export const h2Style = css(
  mq({
    fontSize: [47.9, 47.9, 47.9, 59.9],
    fontWeight: [500, 500, 500, 'normal']
  })
)

export const h3Style = css(
  mq({
    fontSize: [47.9]
  })
)

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9]
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left']
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680]
  })
)

export const linkCardBps = css(
  mq({
    fontSize: [14, 14, 14, 18]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
    margin-bottom: 16px;
  }
`
export const item = css`
  transition: .3s ease-out,transform .3s ease-out,opacity .2s ease-out;
  transition-delay: .1s;
  transition: all .25s linear;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
  :hover {
    transform: translate(0, -4px);
    box-shadow: -4px 10px 20px 0px rgba(0,0,0,0.12);
  }
`

export const cloud = css`
  background-color: black;
  height: 35px;
  width: 120px;
  position: absolute;
  border-radius: 120px;
  z-index: -3;

  &::before{
    position: absolute;
    content: "";
    background-color: black;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    bottom: 10px;
    left: 15px;
  }

  &::after{
    position: absolute;
    content: "";
    background-color: black;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    bottom: 10px;
    right: 10px;
  }
`

export const cloudAni = keyframes`
  100%{
    transform: translateX(-1000px);
  }
`

export const jetpackFloat = keyframes`
  0% {
    transform: translate3d(0, 0, 0)
  }
  50% {
    transform: translate3d(0, 30px, 0)
  }
  100% {
    transform: translate3d(0, 0, 0)
  }
`

export const imgAni = css`
  animation: ${jetpackFloat} 5s ease-in-out infinite;
`
export const imgHomeBox = css(
  mq({
    width: [70, 70, 114, 132],
    height: [70, 70, 114, 132]
  })
)

export const solutionContent = css`
  h6 {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const btn = css`
  :active{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  }
  :hover {
    background-color: red;
  }
`

export const linkCard = css`
  ${linkCardBps};
  color: #FF5800;
  padding: 6px 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);
    padding: 6px 8px;
    border-radius: 4px;
  }
`

export const scrollAction = css`
  overflow-y: hidden !important;
`

export const imageSVG = css`
  img {
    width: 68px !important;
    height: 68px !important;
  }
`
export const imageSVG2 = css`
  img {
    width: 140px !important;
  }
`
export const imageSVG3 = css`
  img {
    width: 180px !important;
  }
`
export const cookieBtn = css`
  width: 130px;
  height: 45px;
  border-radius: 4.2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background-color: #fafafa;
  font-size: 13.7px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`

export const cookieMargin = css`
  ${cookieMarginBps};
`

export const contentDesc = css`
  width: 100%;
  h5 {
    font-size: 30.6px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    ::before {
      border-top: 2px solid #ff3b30;
        content:"";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 58%; 
        left: 0; 
        right: 0; 
        bottom: 0;
        width: 60%;
    }
    span {
      font-size: 18.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.24px;
      text-align: center;
      color: #ffff;
      b {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  h6 {
    font-size: 30.6px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: #ffffff;
    align-items: center;
    display: flex;
    justify-content: center;
    span {
      font-size: 18.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.24px;
      text-align: center;
      color: #ffffff;
      b {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #fff;
    span {
      font-weight: bold;
    }
  }
  p:not(:last-child) {
    margin-bottom: 2px;
  }
`

export const contentDescBold = css`
  width: 100%;
  h5 {
    font-size: 30.6px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    align-items: center;
    display: flex;
    justify-content: center;
    ::before {
      border-top: 2px solid #ff3b30;
        content:"";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 58%; 
        left: 0; 
        right: 0; 
        bottom: 0;
        width: 60%;
    }
    span {
      font-size: 18.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.24px;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
      b {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  h6 {
    font-size: 30.6px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    align-items: center;
    display: flex;
    justify-content: center;
    span {
      font-size: 18.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      b {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    span {
      font-weight: bold;
    }
  }
  p:not(:last-child) {
    margin-bottom: 2px;
  }
`

export const acceptTerms = css`
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 1.36 !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100%;
  max-width: 328px;
`

export const imgCarousel = css`
  width: 100% !important;
  height: 100% !important;
`