import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledCloudServerBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const IdProtectListBps = css(
  mq({
    marginBottom: [0, 0, 30, 30]
  })
)
export const styledCloudServer = css`
  ${styledCloudServerBps};
  h5 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    margin-bottom: 16px;
  }
`

export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    letterSpacing: [0.25],
    textAlign: ['left']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['center']
  })
)

export const PBps = css(
  mq({
    textAlign: ['center']
  })
)

export const PTableBps = css(
  mq({
    width: [130, 328],
    textAlign: ['center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['center']
  })
)

export const imgBps = css(
  mq({
    maxWidth: [290, 350, 400, 450],
    height: [290, 350, 400, 450]
  })
)

export const highlightBoxBps = css(
  mq({
    width: [328, 422, 680, 680],
    marginLeft: [16, 89, 200, 429],
    marginRight: [16, 89, 200, 429],
    paddingLeft: [16, 63, 0, 0],
    paddingRight: [16, 63, 0, 0],
    // paddingTop: [50],
    // paddingBottom: [34]
  })
)

export const tableBps = css(
  mq({
    width: [328, '100%', 680, 680],
    marginLeft: [16, 0, 200, 429],
    marginRight: [16, 0, 200, 429],
    paddingTop: [50],
    paddingBottom: [34]
  })
)

export const contentExpert = css(
  mq({
    marginTop: [50, 50, 74, 74],
    marginLeft: [0, 0, 49, 118],
    marginRight: [0, 0, 92, 92],
    paddingRight: [0, 70, 0, 0],
    paddingLeft: [0, 70, 0, 0],
    marginBottom: [30, 30, 74, 74]
  })
)

export const imgVemPraNuvem = css(
  mq({
    display: ['flex !important', 'flex !important', 'none !important', 'none !important'],
    alignItems: ['center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
  img {
    ${imgBps};
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

export const highlightBox = css`
  ${highlightBoxBps};
  margin: 0 auto !important;
  background: #9013fe;
  border-radius: 16px;
`

export const contactExpert = css`
  margin: 0 auto !important;
  background: #9013fe;
  border-radius: 16px;
`

export const circle = css`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f3f5f5;
  border: 2px solid #fff;
  box-shadow: 0 0 0 4px #f3f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23.7px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`
export const stepsCloud = css`
  h5 {
    color: rgba(0, 0, 0, 0.87);
    margin-top: 16px;
    margin-bottom: 24px;
  }
  img {
    margin-bottom: 24px;
    max-width: 323px;
  }
  p {
    ${PBps};
  }
`
export const steptCloudItem = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const listCloudItem = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.8px;
    font-weight: 500;
    line-height: 1.77;
    letter-spacing: 0.5px;
    color: #f9fafa;
  }
`
export const listStorage = css`
  display: flex;
  align-items: end;
  margin-bottom: 24px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.7);
  }
`
export const faqCloudServer = css`
  background-color: #f3f5f5;
  display: flex;
  justify-content: center;
  h4 {
    color: rgba(0, 0, 0, 0.87);
  }
`

export const table = css`
  ${tableBps};
  margin: 0 auto !important;
  border-radius: 16px;
  p {
    ${PTableBps};
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
  }
`

export const iconeX = css`
  font-size: 11.8px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 30px;
  padding-right: 30px;
`

export const imgPotencial = css`
  background-color: #ffff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
`

export const IdProtectList = css`
  display: flex;
  align-items: end;
  ${IdProtectListBps};
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const precosIcons = css`
  background-color: #ff5800;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const consultingText = css`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`

export const textSpace = css`
  margin-bottom: 24px;
`

export const titleExpert = css`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  margin-bottom: 24px;
`

export const licensesPrice = css`
  background: #7f22fd;
  color: #fff;
  padding: 2px;
  font-weight: bold;
`
export const imgSize = css`
  height: auto !important;
  width: auto !important;
  img {
    height: auto !important;
    width: auto !important;
  }
  svg {
    height: auto !important;
    width: auto !important;
  }
  picture {
    height: auto !important;
    width: auto !important;
  }
`

export const imgClipagem = css`
  background-color: #ffff;
  width: 180px;
  height: 180px;
  border-radius: 50%;
`