import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { load } from 'recaptcha-v3';
 
const RecaptchaKey = ({ onVerifyToken }) => {
  const generateToken = async () => {
    try {
      const recaptcha = await load('6Lfy5D4aAAAAACpSVQje9rYem-t5FnhlAq2I0K9_');
      const generatedToken = await recaptcha.execute('form');
     
      console.log(generatedToken);
      onVerifyToken(generatedToken);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    generateToken();
  }, []);

  return null;
}

RecaptchaKey.propTypes = {
  onVerifyToken: PropTypes.func.isRequired,
};

export default RecaptchaKey;