export const validateEmail = value => {
  let error;

  if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
    error = 'Email inválido';
  }

  return error;
};

export const validateRequiredEmail = value => {
  let error;

  if (!value) {
    return 'Campo Obrigatório';
  }

  if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
    error = 'Email inválido';
  }

  return error;
};
