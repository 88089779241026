import { css } from '@emotion/core';

export const errorCss = css`
  position: absolute;
  top: calc(100% + 4px);
  left: 8px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.4px;
  color: #b00020;
`;
