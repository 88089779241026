import React from 'react'
import { Link } from 'gatsby'
import {
  btn, 
  btnLarge,
  btnMedium,
  btnSmall,
  btnMedium_,
  btnMediumV,
  btnLink,
  btnOutlinedNav
} from './styles/OutlineButton.styles'

const Button = (props) => {
  const buttonStyle = 
  props.type === 'btnOutlinedLarge' ? btnLarge :
  props.type === 'btnOutlinedMedium'  ? btnMedium :
  props.type === 'btnOutlinedMedium_'  ? btnMedium_ :
  props.type === 'btnOutlinedMediumV'  ? btnMediumV :
  props.type === 'btnOutlinedSmall'  ? btnSmall :
  props.type === 'btnOutlinedLink'  ? btnLink :
  props.type === 'btnOutlinedNav'  ? btnOutlinedNav :
  btn
  return (
    <Link
      to={props.Link}
      css={buttonStyle}
    >
      { props.children }
    </Link>
  )
}


export default Button
 