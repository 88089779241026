import React from 'react'
import { css } from '@emotion/core'

import { mq } from '../components/styles/Layout.styles'
import Div from '../components/Div'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import Body1 from '../components/Body1'
import ds from '../theme'

const PricePerFeature = ({
  productName,
  unity,
  unityPrice,
  hour,
  priceHour,
  month,
  priceMonth
}) => {
  return (
    <div
      style={{
        padding: 24,
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='column'
        alignItems='left'
      >
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            alignItems: 'center',
            display: 'flex',
            letterSpacing: 0.25,
            width: 288,
            height: 72,
            textAlign: 'left',
            color: '#ff5800'
          }}
        >
          {productName}
        </H4>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <Div
          flexDirection={['row']}
          alignItems={'center'}
          height={[49]}
        >
          <Paragraph 
            style={{
              fontSize: 14,
              textAlign: 'left',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
              width: 70
            }}>
            {unity}
          </Paragraph>
          <Body1 
            style={{
              fontSize: 18,
              textAlign: 'right',
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginLeft: 'auto'
            }}>
            {unityPrice}
          </Body1>
        </Div>
        <Div 
          style={{
            width: 280,
            height: 1,
            borderBottom: 'solid 1px #f3f5f5'
          }}
        />
        <Div
          flexDirection={['row']}
          alignItems={'center'}
          height={[49]}
        >
          <Paragraph 
            style={{
              fontSize: 14,
              textAlign: 'left',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
              width: 70,
              lineHeight: 1.3
            }}>
            {hour}
          </Paragraph>
          <Body1 
            style={{
              fontSize: 18,
              textAlign: 'right',
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginLeft: 'auto',
              lineHeight: 1,
              whiteSpace: 'pre-wrap'
            }}>
            {priceHour}
          </Body1>
        </Div>
        <Div 
          style={{
            width: 280,
            height: 1,
            borderBottom: 'solid 1px #f3f5f5'
          }}
        />
        <Div
          flexDirection={['row']}
          alignItems={'center'}
          height={[49]}
        >
          <Paragraph 
            style={{
              fontSize: 14,
              textAlign: 'left',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
              width: 70,
              lineHeight: 1.3
            }}>
            {month}
          </Paragraph>
          <Body1 
            style={{
              fontSize: 18,
              textAlign: 'right',
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginLeft: 'auto'
            }}>
            {priceMonth}
          </Body1>
        </Div>
      </Div>
    </div>
  )
}

export default PricePerFeature
