import React from 'react';
import CheckBox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const FormikCheckbox = ({ id, label, field, form, style, ...rest }) => {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const handleChange = () => {
    setFieldValue(name, !value);
    setFieldTouched(name, true);
  };

  return (
    <FormControlLabel
      control={<CheckBox for={id} color='primary' />}
      label={label}
      labelPlacement='end'
      id={id}
      name={name}
      onChange={handleChange}
      style={{
        textTransform: 'normal',
        whiteSpace: 'pre-wrap',
        ...style
      }}
      {...rest}
    />
  );   
};

export default FormikCheckbox;
